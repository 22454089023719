.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header {
  background-color: #467dc0;
  padding: 15px;
  align-content: center;
  justify-content: center;
  display: flex;
}

.header img {
  max-height: 100px;
}

.body {
  background-color: #efefef;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.innerbody {
  max-width: 1080px;
  padding-bottom: 0px;
}

.innerbody p {
  font-weight: normal !important;
  font-size: 1em !important;
}

.video {
  max-width: 1280px;
  width: 100%;
  margin-bottom: -80px;
  padding: 20px 40px;
}

.videoWrapper {
  background-color: #467dc0;
  padding: 40px 0px 0px 0px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.footer {
  background-color: #efefef;
  padding: 20px;
  align-content: center;
  justify-content: center;
  display: flex;
  color: #666;
  font-size: 0.8em;
}

.logo {
  max-width: 200px;
}

.title {
  margin: 0px;
  padding: 0px;
  font-size: 2em;
  color: #262626;
  text-align: center;
  line-height: 1.2em;
  margin-bottom: 10px;
}

.subtitle {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  font-size: 1.2em;
  text-align: center;
  color: #3f3f3f;
}

.button {
  font-size: 1em;
  padding: 10px 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .button {
    width: calc(100% - 40px);
  }
}
